<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Дорогие участники программы «Алмазный бонус»!</h1>
        </div>
      </div>

      <p>
        Мы рады приветствовать Вас на портале, предназначенном для обмена заработанных
        Вами бонусов на товары и услуги различных федеральных сетей. Ваше участие в программе означает,
        что вам были начислены бонусы за реализацию техники АЛМАЗ (1 балл равен 1 рублю). Исходя из суммы баллов
        Вы можете выбрать на данном портале Электронные подарочные сертификаты и оплатить ими товары и услуги
        непосредственно в торговых сетях или интернет-магазинах.
      </p>

      <h3>Каталог призов</h3>

      <p>
        В нашем каталоге Вы можете выбрать Электронные подарочные сертификаты многих известных брендов.
        Выбранный Электронный сертификат будет доставлен на указанный e-mail в электронном виде.
      </p>
      <p>Вы можете выбрать любое количество сертификатов в рамках начисленных бонусов в любое удобное для Вас время.</p>
      <p>
        Обязательно внимательно ознакомьтесь с Условиями использования каждого выбираемого сертификата.
        Они устанавливаются организацией, выпустившей данный сертификат.
      </p>
      <p>Если Вы выбрали подарочный сертификат, проверьте наличие магазина, выпустившего его, в Вашем городе.</p>

      <hr>

      <h3>Возникли вопросы?</h3>
      <p>Свяжитесь с нами в рабочие дни <b>с 08:00 до 20:00 (МСК)</b></p>
      <p>Служба заботы о клиентах: <b><a href="tel:88007009090">8&nbsp;800&nbsp;700 90 90</a></b><br>
        Email: <b><a href="mailto:support@giftery.ru">support@giftery.ru</a></b></p>
      <br>
      <router-link class="button button--large button--rounded" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
      <br>
      <br>
    </div>
  </main>
</template>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 32.3%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 32.3%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }

  .text-danger {
    color: #c00;
    font-weight: bold;
  }
</style>
