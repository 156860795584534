<template>
  <main>
    <div class="container">
      <h1>Контакты</h1>
      <hr>
      <h3>Возникли вопросы?</h3>
      <p>Свяжитесь с нами в рабочие дни <b>с 08:00 до 20:00 (МСК)</b></p>
      <p>
        Служба заботы о клиентах: <b><a href="tel:88007009090">8&nbsp;800&nbsp;700 90 90</a></b><br>
        Email: <b><a href="mailto:support@giftery.ru">support@giftery.ru</a></b>
      </p>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Contacts',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--inner')
  }
}
</script>
